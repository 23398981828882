












import {Component, Mixins, Watch} from 'vue-property-decorator';
import TopModels from '@/components/TopModels.vue';
import DropDown from '@/components/DropDown.vue';
import {vxm} from '@/store';
import {VideosSearchParamsInterface} from '@/types/VideosSearchParamsInterface';
import {VideoInterface} from '@/types/videoInterface';
import DebounceMixin from '@/mixins/DebounceMixin';
import Loader from '@/components/Loader.vue';
import PostCardNew from '@/components/PostCardNew.vue';

@Component({
  components: {Loader, TopModels, PostCardNew, DropDown},
})
export default class Webinars extends Mixins(DebounceMixin) {
  vids: VideoInterface[] = [];
  isMobile = false;
  viewType = 'grid';
  params: VideosSearchParamsInterface = {
    page: 1,
    take: 20,
    sort: 'newest',
  };
  isRequestSend = false;
  totalVids = 0;
  processing = false;
  hideAvatar = true;

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  mounted() {
    this.getVids();
    window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (bottomOfWindow && !this.isRequestSend && this.totalVids > this.vids.length) {
      this.getVids();
    }
  }

  refreshVids() {
    this.params.page = 1;
    this.vids = [];
    this.getVids();
  }

  getVids() {
    if (this.params.keyword === '') {
      this.params.keyword = undefined;
    }

    this.isRequestSend = true;

    vxm.model
      .getWorkshopVideo(this.params)
      .then((res) => {
        this.totalVids = res.data.count;
        this.vids.push(
          ...res.data.items.map((item) => {
            item.mediaType = 'clips';
            item.isViewable = true;
            item.performer = [vxm.user.data._id];
            return item;
          }),
        );
        this.vids = this.vids.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
        (this.params.page as number)++;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  switchView(viewType: string) {
    this.viewType = viewType;
  }

  onResize() {
    this.isMobile = window.innerWidth <= 1023;
  }
}
